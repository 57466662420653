import { Button } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { m } from 'framer-motion'

export default function Menu() {
    const navigate = useNavigate()
    const videos = ['/sydney.mp4', '/mountain.mp4', '/lantern.mp4', '/london.mp4'];

    const getRandomVideo = () => {
        const index = Math.floor(Math.random() * videos.length);
        return videos[index];
    };
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <div style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: 'transparent',
                color: '#fff',
                position: 'absolute',
                zIndex: 2
            }}>
                <div style={{ height: 100, width: 200, padding: 30 }}><img src='/logo.jpg' alt='logo' style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => navigate('/')} /></div>
                {/* <div style={{ display: 'flex', gap: '20px', fontSize: '1.2em' }}>
                    <Button type='text' className='home-menu' onClick={() => navigate('/')}>Home</Button>
                    <Button type='text' className='home-menu' onClick={() => navigate('/calendar')}>Calendar</Button>
                </div> */}
            </div>
            <video autoPlay loop muted style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '0',
                top: '0',
                objectFit: 'cover',
                zIndex: '1'
            }}>
                <source src={"https://ie-project.s3.ap-southeast-2.amazonaws.com/home-background-video" + getRandomVideo()} type="video/mp4" />
            </video>
            <div style={{
                position: 'absolute',
                top: '20%',
                left: '25%',
                transform: 'translateX(-50%)',
                zIndex: '2',
                width: '40%', // Ensure div takes the full width of its parent
            }}>
                <m.h1 style={{
                    color: 'white',
                    fontSize: '4.2vw', // Responsive font size
                    textShadow: '2px 2px black'
                }}>
                    Connect Globally, Embrace Culturally.
                </m.h1>
                <br />
                <m.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    style={{
                        width: '15vw',
                        height: 60,
                        zIndex: '2',
                    }}
                >
                    <Button type="primary"
                        onClick={() => navigate('/games')}
                        style={{
                            fontSize: '1.5vw', // Responsive font size
                            height: '100%',
                            width: '100%'
                        }}>
                        Explore Now!
                    </Button>
                </m.div>
            </div></div>
    )
}
