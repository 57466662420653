import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion'; // Import motion
import '../game-layout/GameLayout.css';
import { SideBar } from '../side-menu/SideBar';
import BackButton from '../webpart/BackButton';
import GameOngoingBackground from '../webpart/GameOngoingBackground';
import { useLocation, useNavigate } from 'react-router-dom';
import GameOngoingMain from '../game-ongoing/GameOngoingMain';
import Library from '../library/Library';

export default function GameOngoingLayout() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(useLocation().search);

  const capitalizeFirstLetter = (word) => {
    if (!word) return word;
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const type = urlParams.get('type');
  const mode = localStorage.getItem('mode');
  const country = mode === 'library' ? capitalizeFirstLetter(urlParams.get('country')) : localStorage.getItem('country');

  useEffect(() => {
    if (!mode || !country) {
      navigate('/games');
    } else {
      if (mode !== 'library' && !type) {
        navigate('/games');
      }
      if (mode === 'library' && !country) {
        navigate('/games');
      }
    }
  }, [navigate, mode, country, type]);  // Added dependencies to useEffect

  // Define animation variants for the container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.6 }
    }
  };

  return (
    <AnimatePresence mode='wait'>
      <motion.div // Apply motion.div here
        className='game-background'
        variants={containerVariants} // Use variants for animation
        initial="hidden" // Set the initial state
        animate="visible" // Set the animate state
      >
        <SideBar />
        <BackButton />
        <GameOngoingBackground mode={mode} country={country} />

        {
          mode === 'game' &&
          <motion.div
            initial={{ scale: 0.2, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 1, duration: 0.5, ease: "easeOut" }}
            className="game-ongoing-container">
            <GameOngoingMain country={country} type={type} />
          </motion.div>
        }
        {
          mode === 'library' && country &&

          <motion.div
            initial={{ scale: 0.2, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 1, duration: 0.5, ease: "easeOut" }}
            className="library-ongoing-container">
            <Library country={country} />
          </motion.div>
        }

      </motion.div>
    </AnimatePresence>
  );
}
