import React from 'react';
import { motion } from 'framer-motion';
import './GameStart.css';
import { useNavigate } from 'react-router-dom';
import { Card, message } from 'antd';

const { Meta } = Card;

export default function GameStart() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const selectMode = (mode) => {
        switch (mode) {
            case 'game':
                navigate('/games/game-initial?mode=game');
                break;
            case 'library':
                navigate('/games/game-initial?mode=library');
                break;
            default:
                messageApi.warning('Something error, please try again.');
        }
    }

    return (
        <>
            {contextHolder}
            <div className='game-start'>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <div className='content'>
                        <motion.h1
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 1, duration: 0.5 }}
                        >
                            Select a Mode
                        </motion.h1>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: 20 }}>
                            <motion.div
                                initial={{ y: 30, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: 1, duration: 0.5 }}
                            >
                                <Card
                                    hoverable
                                    className="card-hover-effect"
                                    style={{
                                        width: 240
                                    }}
                                    onClick={() => selectMode('game')}
                                    cover={
                                        <img
                                            alt="Game mode"
                                            src="/side-menu-icon/game-controller.png"
                                            style={{
                                                width: '180px',
                                                height: '180px',
                                                display: 'block',
                                                margin: 'auto'
                                            }}
                                        />
                                    }
                                >
                                    <Meta title="Game" description="Explore Cultural Through 3 Exciting Game Modes Across 6 Nations" />
                                </Card>
                            </motion.div>
                            <motion.div
                                initial={{ y: 30, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: 1.2, duration: 0.5 }}
                            >
                                <Card
                                    hoverable
                                    className="card-hover-effect"
                                    style={{
                                        width: 240
                                    }}
                                    onClick={() => selectMode('library')}
                                    cover={
                                        <img
                                            alt="Info mode"
                                            src="/side-menu-icon/graduation-hat.png"
                                            style={{
                                                width: '180px',
                                                height: '180px',
                                                display: 'block',
                                                margin: 'auto'
                                            }}
                                        />
                                    }
                                >
                                    <Meta title="Library Station" description="Unlock Insights into Diverse Cultures Without Playing Games!" />
                                </Card>
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
}
