import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../components/home/Home';
import GameLayout from '../components/game-layout/GameLayout';
import GameStart from '../components/game-start/GameStart';
import GameInitial from '../components/game-start/GameInitial';
import GameMenu from '../components/game-menu/GameMenu';
import GameOngoingLayout from '../components/game-layout/GameOngoingLayout'
import FestivalCalendar from '../components/calendar/FestivalCalendar';

export default function IndexRouter() {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path='games' element={<GameLayout />}>
                <Route index element={<GameStart />} />
                <Route path='game-initial' element={<GameInitial />} />
                <Route path='game-menu' element={<GameMenu />} />
                <Route path='*' element={<GameStart />}></Route>
            </Route>
            <Route path='game-ongoing' element={<GameOngoingLayout />} />
            <Route path='library' element={<GameOngoingLayout />} />
            <Route path='calendar' element={<FestivalCalendar />} />
            <Route path='*' element={<Home />} />
        </Routes>
    );
}
