import React, { useEffect, useRef, useState } from 'react';
import './GameStyle.css';
import { Col, Modal, Row, Spin, Tour, message, notification } from 'antd';
import ReactiveButton from 'reactive-button';
import StreetView from '../webpart/StreetView';
import axios from 'axios';
import GameSummary from './GameSummary';
import GameRightPanel from './GameRightPanel';

export default function GameOngoingMain(props) {
  const [selected, setSelected] = useState(null);
  const [questionNo, setQuestionNo] = useState(0);
  const [question, setQuestion] = useState();
  const [tourOpen, setTourOpen] = useState(false);
  const [correctNum, setCorrectNum] = useState(0)
  const [summary, setSummary] = useState(false)
  const [questionList, setQuestionList] = useState([])
  const [messageApi, contextHolder] = message.useMessage()
  const [api, notificationHolder] = notification.useNotification();
  const { country, type } = props

  // Refs for tour targets
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const modalItem = {
    content: (
      <>
        <Row gutter={20}>
          <Col span={8}>
            <img src={question?.img_path} style={{ width: '100%' }} />
          </Col>
          <Col span={16}>
            {question?.correct_answer.description}
          </Col>
        </Row>
      </>
    ),
    footer: (_) => (
      <>
        <ReactiveButton rounded idleText={questionNo === 4 ? 'View Summary' : 'Next Question'} onClick={() => {
          Modal.destroyAll()
          setSelected(null)
          if (questionNo === 4) {
            setSummary(true)
          } else {
            setQuestion(questionList[questionNo + 1])
            setQuestionNo(questionNo + 1)
          }
        }
        }
        />

      </>
    ),
    closeIcon: false,
    maskClosable: false,
    width: '50%'
  }


  const steps = [
    {
      title: 'Drag Image',
      description: 'Drag the image to view the street view.',
      cover: <video autoPlay loop muted src='https://ie-project.s3.ap-southeast-2.amazonaws.com/game-tour/map-teach.mp4' style={{ width: '100%', height: '100%' }} />,
      target: () => ref1.current,
    },
    {
      title: 'Answer The Question',
      description: 'Select one option to answer the question.',
      target: () => ref2.current,
    },
    {
      title: 'Submit The Question',
      description: 'Click to see check result.',
      target: () => ref3.current,
    },
  ];

  useEffect(() => {
    setQuestion(null)
    axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/games/attraction?culture=attraction&country=${country}`).then((res => {
      setQuestionList(res.data)
      setQuestion(res.data[questionNo]);
      if (!localStorage.getItem('placeTourOpen')) {
        setTimeout(() => {
          setTourOpen(true);
          localStorage.setItem('placeTourOpen', 'true');
        }, 1500)
      }
    })).catch((err) => {
      console.log('error', err);
    });
  }, []);

  const handleSelect = (index) => {
    setSelected(index);
  };

  const getResult = () => {
    if (selected != null) {
      if (selected === question.correct_answer.id) {
        setCorrectNum(correctNum + 1)
        api.destroy()
        api['success']({ message: 'Correct Answer!' })
        Modal.success({ ...modalItem, title: 'Correct Answer!' })
      } else {
        api.destroy()
        api['error']({ message: 'Wrong Answer!' })
        Modal.error({ ...modalItem, title: 'Wrong Answer.' })
      }

    } else {
      messageApi.warning('Please select an option')
    }

  }


  return (
    <>
      {contextHolder}
      {notificationHolder}
      <div className='ongoing-place-game-layout'>

        {question ?
          <Row gutter={20}>
            {summary ? <>
              <GameSummary round={questionNo + 1} correct={correctNum} country={country} />
            </> :
              <>
                <Col span={14} className='game-left' ref={ref1}>
                  <StreetView key={question?.street_view_url} src={question?.street_view_url} />
                </Col>
                <Col span={10} className='game-right'>
                  <GameRightPanel
                    question={question}
                    selected={selected}
                    onSelect={handleSelect}
                    onResult={getResult}
                    questionNo={questionNo}
                    ref2={ref2}
                    ref3={ref3}
                  />
                </Col>
              </>
            }
          </Row>
          :
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <Spin size="large" style={{ fontSize: 80 }}>
              <div className="content" />
            </Spin>
          </div>
        }


        <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
      </div>
    </>
  );
}
