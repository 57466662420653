import React from 'react'
import { motion } from 'framer-motion';
import './GameOngoingBackground.css'

export default function GameOngoinBackground(props) {
  const { mode, country } = props
  return (
    <>
      <div style={{
        backgroundColor: mode === 'game' ? '#4ccaf9' : '#E9800F',
        position: 'fixed',
        right: 0,
        bottom: 0,
        minWidth: '100%',
        minHeight: '100%',
        zIndex: -1
      }}></div>
      <motion.div
        style={{
          height: '100vh',
          position: 'absolute',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        initial={{ scale: 0.2, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5, ease: "easeOut" }}
      >
        {country === 'China' && <img src='/country-icon/chinese-wall.png' alt='great-wall' className='background-img' />}
        {country === 'India' && <img src='/country-icon/taj-mahal.png' alt='taj-mahal' className='background-img' />}
        {country === 'Australia' && <img src='/country-icon/opera-house.png' alt='opera-house' className='background-img' />}
        {country === 'England' && <img src='/country-icon/tower-bridge.png' alt='tower-bridge' className='background-img' />}
        {country === 'Thailand' && <img src='/country-icon/wat-phra-kaew.png' alt='wat-phra-kaew' className='background-img' />}
        {country === 'Vietnam' && <img src='/country-icon/vietnamese.png' alt='vietnamese' className='background-img' />}
      </motion.div>
    </>
  )
}
