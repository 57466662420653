import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import './GameInitial.css';
import { EffectCoverflow, Pagination } from 'swiper/modules';
import ReactiveButton from 'reactive-button';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tag } from 'antd';

export default function GameInitial() {
    const [selectedCountry, setSelectedCountry] = useState('Australia');
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(useLocation().search)
    const mode = urlParams.get('mode')

    useEffect(() => {
        if (mode != 'game' && mode != 'library') {
            navigate('/games')
        } else {
            localStorage.setItem('mode', mode)
        }
    }, [mode])

    const handleCountrySelect = (country) => {
        setSelectedCountry(country);
    };

    return (
        <div className='initial-container'>

            <motion.h1
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
            >Swipe to select a country:</motion.h1>

            <motion.div initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Tag color={mode === 'game' ? '#108ee9' : '#f50'}>{mode}</Tag>
            </motion.div>

            <motion.div initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5, duration: 1 }}>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className="initial-swiper"
                    onSlideChange={(swiper) => handleCountrySelect(swiper.slides[swiper.activeIndex].getAttribute('data-country'))}>
                    <SwiperSlide data-country="Australia">
                        <img src="/country-icon/australia.png" />
                    </SwiperSlide>
                    <SwiperSlide data-country="China">
                        <img src="/country-icon/china.png" />
                    </SwiperSlide>
                    <SwiperSlide data-country="England">
                        <img src="/country-icon/england.png" />
                    </SwiperSlide>
                    <SwiperSlide data-country="India">
                        <img src="/country-icon/india.png" />
                    </SwiperSlide>
                    <SwiperSlide data-country="Thailand">
                        <img src="/country-icon/thailand.png" />
                    </SwiperSlide>
                    <SwiperSlide data-country="Vietnam">
                        <img src="/country-icon/vietnam.png" />
                    </SwiperSlide>
                </Swiper>
            </motion.div>

            <ReactiveButton idleText={mode === 'library' ? 'VIEW NOW...' : 'CONTINUE...'} color={mode === 'library' && 'yellow'} style={{
                width: 300,
                height: 70,
                fontSize: '1.5em'
            }} onClick={() => {
                if (mode === 'game') {
                    navigate(`/games/game-menu?country=${selectedCountry}`)
                } else {
                    localStorage.setItem('country', selectedCountry)
                    navigate(`/library?country=${selectedCountry}`)
                }
            }
            } />
        </div>
    );
}
