import { Button } from 'antd';
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons'

export default function BackButton() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const country = queryParams.get('country');
    const mode = queryParams.get('mode')

    const goBack = () => {
        if (location.pathname === '/games/game-initial') {
            if (location.search === '?mode=game' || location.search === '?mode=library') {
                navigate('/games');
            }
        } else if (location.pathname === '/games/game-menu' && ['China', 'England', 'India', 'Australia', 'Thailand', 'Vietnam'].includes(country)) {
            navigate('/games/game-initial?mode=game');
        } else if (location.pathname === '/library' && ['China', 'England', 'India', 'Australia', 'Thailand', 'Vietnam'].includes(country)) {
            navigate('/games/game-initial?mode=library');
        }
        else {
            navigate(-1);
        }
    };

    return (
        <div style={{ position: 'absolute', right: 25, top: 8, zIndex: 3, padding: '1px 6px', borderRadius: 50, backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, cursor: 'pointer' }}>
            <div onClick={goBack} style={{ height: 40, width: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <RollbackOutlined style={{ fontSize: 23 }} />
            </div>
        </div>
    )
}
