import React, { useEffect, useRef, useState } from 'react';
import HTMLFlipBook from "react-pageflip";
import './Library.css';
import { Button, Col, Row, Spin, Tooltip, Tour, message } from 'antd';
import ReactiveButton from 'reactive-button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Library(props) {
    const navigate = useNavigate()
    const bookRef = useRef(null);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [tourOpen, setTourOpen] = useState(false);
    const [bookContent, setBookContent] = useState([])
    const { country } = props;
    const libraryTourOpen = localStorage.getItem('libraryTourOpen')
    const [messageApi, contextHolder] = message.useMessage();

    const steps = [
        {
            title: 'Flip Book',
            description: 'Click or Swipe the book from bottom right to left.',
            cover: <video autoPlay loop muted src='https://ie-project.s3.ap-southeast-2.amazonaws.com/game-tour/book-teach.mp4' style={{ width: '100%', height: '100%' }} />,
            target: () => ref1.current,
        },
        {
            title: 'Button Control',
            description: 'You can also click on buton to control page.',
            target: () => ref2.current,
        },
        {
            title: 'Select a Menu',
            description: 'Click on one of the menus to view different types of culture.',
            target: () => ref3.current,
        },
    ];

    useEffect(() => {
        setBookContent([])
        axios.get(`https://9f8rfvs6cd.execute-api.ap-southeast-2.amazonaws.com/explore/api/library/attraction?country=${country}`).then(res => {
            setBookContent(res.data)
            if (!libraryTourOpen) {
                setTimeout(() => {
                    setTourOpen(true)
                    localStorage.setItem('libraryTourOpen', true)
                }, 1500)
            }
        }).catch((err) => {
            console.log(err)
            messageApi.info('Please use a correct country!');
            navigate('/games/game-initial?mode=library')
        })
    }, [country])

    const goToNextPage = () => {
        bookRef.current.pageFlip().flipNext(); // Flip to the next page
    };

    // Assuming you might also want a function to go to the previous page
    const goToPreviousPage = () => {
        bookRef.current.pageFlip().flipPrev(); // Flip to the previous page
    };

    const goToCatalogue = () => {
        bookRef.current.pageFlip().flip(3); // Page index starts at 0, so 3 is the fourth page
    };

    const onTourChange = (e) => {
        if (e === 1) {
            goToCatalogue()
        }
    }

    return (
        <>
            {contextHolder}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100%' }}>
                {bookContent.length > 0 ?
                    <>
                        <HTMLFlipBook ref={bookRef} width={550} height={700}>
                            <div className="demoPage firstPage"></div>
                            <div className="demoPage book-cover" ref={ref1}>
                                <div style={{
                                    width: '90%',
                                    height: '90%',
                                    position: 'absolute',
                                    top: '5%',
                                    left: '5%',
                                    border: '1px solid #FFC603',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <h1 style={{ color: '#FFC603', textAlign: 'center' }}>THE CULTURE OF {country.toUpperCase()}</h1>
                                </div>
                            </div>
                            <div className="demoPage">
                                <div className='page-header'></div>
                                <div className='page-layout' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ textAlign: 'center' }}>THE CULTURE OF {country.toUpperCase()}</h1>
                                </div>
                            </div>
                            <div className="demoPage">
                                <div className='page-header'></div>
                                <div className='page-layout'>
                                    <h2 style={{ textAlign: 'center' }}>Catalogue</h2>
                                    <ul>
                                        <li><Button type='text' ref={ref3}>Famous Place of {country}</Button></li>
                                        <li><Tooltip title='not published...' color='orange'><Button style={{ cursor: 'not-allowed' }} type='text' disabled>Food of {country}</Button></Tooltip></li>
                                        <li><Tooltip title='not published...' color='orange'><Button style={{ cursor: 'not-allowed' }} type='text' disabled>Language of {country}</Button></Tooltip></li>
                                    </ul>
                                </div>
                            </div>
                            {
                                bookContent.map(page => (
                                    <div className="demoPage" key={page.id}>
                                        <div className='page-header'>{page.header.toUpperCase()}</div>
                                        <div className='page-layout'>
                                            <div className='page-inner'>
                                                <img src={page.image_url} alt={page.header} />
                                                <h4 className='page-topic'>{page.topic}</h4>
                                                {page.detail}
                                            </div>
                                        </div>
                                        <div className='page-footer'>{page.id + 1}</div>
                                    </div>
                                ))
                            }


                        </HTMLFlipBook>
                        <Row gutter={20} ref={ref2} style={{
                            marginTop: 30
                        }}>
                            <Col span={8}>
                                <ReactiveButton idleText='<<' onClick={goToPreviousPage} />
                            </Col>
                            <Col span={8}>
                                <ReactiveButton idleText='Catalogue' onClick={goToCatalogue} />
                            </Col>
                            <Col span={8}>
                                <ReactiveButton idleText='>>' onClick={goToNextPage} />
                            </Col>
                        </Row>
                        <Tour open={tourOpen} onChange={(e) => onTourChange(e)} onClose={() => { setTourOpen(false) }} steps={steps} />
                    </>
                    :
                    <div style={{ backgroundColor: 'white' }}>
                        <Spin size='large' />
                    </div>
                }

            </div>
        </>
    );
}
