import React from 'react';
import { Col, Layout, Row } from 'antd';
import { motion } from 'framer-motion'
import ReactiveButton from 'reactive-button';

const { Header, Content, Footer } = Layout;

const borderColors = ["#FF008C", "#D309E1", "#9C1AFF", "#7700FF"];

function GameRightPanel({ question, selected, onSelect, onResult, questionNo, ref2, ref3 }) {
    return (
        <div style={{ overflow: 'hidden', width: '100%', maxWidth: '100%', height: '100%' }}>
            <div className='game-header'>
                <Row>
                    <Col xs={24} md={6} style={{ height: '100%' }}>
                        <img src='/game-icon/question-mark.png' alt='question-mark' style={{ height: '100%' }} />
                    </Col>
                    <Col xs={24} md={18} style={{ fontSize:30, color: '#ff2147', textShadow: '2px 1px #E2DDDE',height:'100%', display:'flex',alignItems:'center' }}>
                        <b>Question {questionNo + 1}/5</b>
                    </Col>
                </Row>
            </div>
            <div className='game-content'>
                <div style={{ overflow: 'hidden', width: '100%', maxWidth: '100%', height: '100%', backgroundColor: 'transparent' }}>
                    <div className='game-question'><h4>{question.question}</h4></div>
                    <div className='game-answer' ref={ref2}>
                        <ul style={{ padding: 20 }}>
                            {question.answers.map((option, index) => (
                                <li key={index}>
                                    <motion.div
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.8 }}
                                        className='answer-option'
                                        style={{
                                            borderColor: borderColors[index],
                                            borderWidth: selected === index ? '5px' : '1px',
                                        }}
                                        onClick={() => onSelect(index)}
                                    >
                                        {option.description}
                                    </motion.div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='game-submit'>
                <div style={{ height: '100%', width: 200 }} ref={ref3}>
                    <ReactiveButton rounded idleText='SUBMIT' style={{ height: '100%', width: 200 }} onClick={onResult} />
                </div>
            </div>
        </div>
    );
}

export default GameRightPanel;
