import React from 'react'
import { motion } from 'framer-motion';
import Map from 'react-map-gl';

export default function GameBackground() {
    return (
        <>
            <video
                autoPlay
                muted
                style={{
                    position: 'fixed',
                    right: 0,
                    bottom: 0,
                    minWidth: '100%',
                    minHeight: '100%',
                    zIndex: -1
                }}
                src="https://ie-project.s3.ap-southeast-2.amazonaws.com/game-background.mp4"
            ></video>

            <motion.div
                style={{
                    height: '100vh',
                    position: 'absolute',
                    width: '100%',
                    top: 0,
                    left: 0,
                }}
                initial={{ scale: 0.2, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.5, ease: "easeOut" }}
            >
                <Map
                    style={{ width: '100%', height: '100%' }}
                    mapStyle="mapbox://styles/ryelei/clu689myx006301ra2n7f92b5"
                    mapboxAccessToken="pk.eyJ1IjoicnllbGVpIiwiYSI6ImNsOHh1OTV6MjAweGozcHBscnF4cDJzOGEifQ.46Qri6XOPhJUAW9j649-BA"
                    projection="globe"
                    initialViewState={{
                        longitude: 104.195397,
                        latitude: 35.86166,
                        zoom: 2,
                    }}
                    attributionControl={false}
                    zoomControl={false}
                />
            </motion.div>
        </>
    )
}
