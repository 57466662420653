import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactiveButton from 'reactive-button';
import 'swiper/css';
import 'swiper/css/effect-cards';
import './GameMenu.css';
import { Col, Row, Tooltip, message } from 'antd';

// Define useQuery as a custom hook inside the component or in its own module
function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

export default function GameMenu() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const query = useQuery();
  const country = query.get('country');
  const mode = query.get('mode');

  useEffect(() => {
    // Check if country is not one of the specified names and mode is not 'game' or 'library'
    if (!['China', 'England', 'India', 'Australia', 'Thailand', 'Vietnam'].includes(country)) {
      navigate('/games');
    } else {
      if (mode) {
        localStorage.setItem('mode', mode);
      }
      if (country) {
        localStorage.setItem('country', country);
      }
    }
  }, [country, mode, navigate]);

  const selectType = (type) => {
    switch (type) {
      case 'attraction':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?type=${type}`);
        break;
      case 'food':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?type=${type}`);
        break;
      case 'language':
        localStorage.setItem('type', type)
        navigate(`/game-ongoing?type=${type}`);
        break;
      default:
        messageApi.warning('Something error, please try again.');
    }
  }

  return (
    <>
      {contextHolder}
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }} className='menu-container'>
        <motion.h1 initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.4, duration: 0.5 }}>
          Select a culture type:
        </motion.h1>
        <Row gutter={18} style={{ width: '50vw', height: '100%' }}>
          <Col span={8} className='menu-col'>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.5, duration: 0.5 }}>
              <ReactiveButton onClick={() => selectType('attraction')} color="light" idleText={<>
                <img src='/game-icon/great-wall.png' alt='great-wall' />
                <h2>Place</h2>
              </>} />
            </motion.div>
          </Col>
          <Col span={8} className='menu-col'>
            <Tooltip color='#2db7f5' title='Under construction...'>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.6, duration: 0.5 }}>
                <ReactiveButton disabled onClick={() => selectType('food')} style={{cursor:'not-allowed'}} color="light" idleText={<>
                  {/* <img src='/game-icon/curry.png' alt='curry' /> */}
                  <img src='/game-icon/lock.png' alt='lock' />
                  <h2>Food</h2></>}
                />
              </motion.div>
            </Tooltip>
          </Col>
          <Col span={8} className='menu-col'>
            <Tooltip color='#2db7f5' title='Under construction...'>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.7, duration: 0.5 }}>
                <ReactiveButton disabled onClick={() => selectType('language')} style={{cursor:'not-allowed'}} color="light" idleText={<>
                  {/* <img src='/game-icon/languages.png' alt='curry' /> */}
                  <img src='/game-icon/lock.png' alt='lock' />
                  <h2>Language</h2></>}
                />
              </motion.div>
            </Tooltip>
          </Col>
        </Row>
      </div>
    </>
  );
}
