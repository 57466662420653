import React, { useRef, useEffect, useState } from 'react';
import './StreetView.css';

export default function StreetView(props) {
    const mapRef = useRef(null);
    const [isApiLoaded, setApiLoaded] = useState(false);

    const loadGoogleMapsApi = () => {
        if (window.google && window.google.maps) {
            setApiLoaded(true);
        } else if (!isApiLoaded) { // Only attempt to load the script if it hasn't been loaded yet
            const existingScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyANe2p6xtoYaryvmTib7nXDpNPo3repac4&callback=initMap`;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
    
                window.initMap = () => setApiLoaded(true);
            }
        }
    };
    

    useEffect(() => {
        loadGoogleMapsApi();
    }, []);

    useEffect(() => {
        if (isApiLoaded) {
            const google = window.google;
            const url = props.src;
            const streetViewParameters = parseStreetViewUrl(url);

            if (streetViewParameters) {
                new google.maps.StreetViewPanorama(mapRef.current, {
                    position: { lat: streetViewParameters.lat, lng: streetViewParameters.lng },
                    pov: { heading: streetViewParameters.heading, pitch: 10 },
                    disableDefaultUI: true,
                    panControl: false,
                    zoomControl: false,
                    showRoadLabels: false,
                    streetViewControl: false,
                    motionTracking: false,
                    motionTrackingControl: false,
                    scrollwheel: false,
                    draggable: false
                });
            }
        }
    }, [isApiLoaded]);

    const parseStreetViewUrl = (url) => {
        const match = url.match(/@([-.\d]+),([-.\d]+),\d+a,(\d+)y,([-.\d]+)h,([-.\d]+)t/);
        if (match) {
            return {
                lat: parseFloat(match[1]),
                lng: parseFloat(match[2]),
                heading: parseFloat(match[4])
            };
        }
        return null;
    };

    return (
        <div ref={mapRef} style={{ width: '100%', height: '100%', borderRadius: '10px' }}>Map</div>
    );
}
