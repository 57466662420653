import React from 'react';
import './GameLayout.css';
import GameRouter from '../../router/GameRouter';
import { SideBar } from '../side-menu/SideBar';
import BackButton from '../webpart/BackButton';
import GameBackground from '../webpart/GameBackground';

export default function GameLayout() {

  return (
    <div className='game-background'>
      <SideBar />
      <BackButton />
      <GameBackground />
      <div className="router-container">
        <GameRouter />
      </div>
    </div>
  );
}
