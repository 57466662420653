import React from 'react'
import Menu from '../home/Menu'
import { motion } from 'framer-motion'
import { Badge, Calendar, Col, Row, Select, Tag } from 'antd';
import './FestivalCalendar.css'

const getListData = (value) => {
    let listData;
    switch (value.date()) {
        case 8:
            listData = [
                {
                    type: 'warning',
                    content: 'This is warning event.',
                },
                {
                    type: 'success',
                    content: 'This is usual event.',
                },
            ];
            break;
        case 10:
            listData = [
                {
                    type: 'warning',
                    content: 'This is warning event.',
                },
                {
                    type: 'success',
                    content: 'This is usual event.',
                },
                {
                    type: 'error',
                    content: 'This is error event.',
                },
            ];
            break;
        case 15:
            listData = [
                {
                    type: 'warning',
                    content: 'This is warning event',
                },
                {
                    type: 'success',
                    content: 'This is very long usual event......',
                },
                {
                    type: 'error',
                    content: 'This is error event 1.',
                },
                {
                    type: 'error',
                    content: 'This is error event 2.',
                },
                {
                    type: 'error',
                    content: 'This is error event 3.',
                },
                {
                    type: 'error',
                    content: 'This is error event 4.',
                },
            ];
            break;
        default:
    }
    return listData || [];
};
const getMonthData = (value) => {
    if (value.month() === 8) {
        return 1394;
    }
};
export default function FestivalCalendar() {
    const monthCellRender = (value) => {
        const num = getMonthData(value);
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null;
    };
    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content}>
                        <Badge status={item.type} text={item.content} />
                    </li>
                ))}
            </ul>
        );
    };
    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        if (info.type === 'month') return monthCellRender(current);
        return info.originNode;
    };
    const onChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    return (
        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}>
            <Menu />
            <Row style={{
                width: '80vw',
                background: 'rgb(255,255,255,0.8)',
                position: 'relative',
                zIndex: 3,
                top: '10vh',
                left: '10vw',
                borderRadius: 10
            }} gutter={20}>
                <Col span={16}>
                    <motion.div initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}>
                        <Calendar cellRender={cellRender} />
                    </motion.div>
                </Col>
                <Col span={8}>
                    <div style={{
                        position: 'absolute',
                        top: '30%',
                        height: '20%'
                    }}>
                        <h1 style={{ fontSize: '2.5em' }}>Select a country to view the festivals:</h1>
                        <Select
                            showSearch
                            placeholder="Select a country"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            style={{
                                width: '90%'
                            }}
                            options={[
                                {
                                    value: 'china',
                                    label: 'China',
                                },
                                {
                                    value: 'australia',
                                    label: 'Australia',
                                },
                                {
                                    value: 'india',
                                    label: 'India',
                                },
                            ]}
                        />
                    </div>

                </Col>
            </Row>
        </motion.div>
    )
}
