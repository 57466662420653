import React, { useState } from 'react';
import { Button, Spin } from 'antd';
import { LazyMotion, m, domAnimation, useViewportScroll, useTransform } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { DoubleRightOutlined, LoadingOutlined } from '@ant-design/icons'
import './Home.css'
import Menu from './Menu';

export default function Home() {
  const navigate = useNavigate()
  const [vsLoaded, setVsLoaded] = useState(false)

  const { scrollY } = useViewportScroll();
  const scale = useTransform(scrollY, [0, 1000], [1, 1.5]);
  const flashingAnimation = {
    opacity: [0, 1, 0],
    transition: { repeat: Infinity, duration: 3 }
  };

  return (
    <LazyMotion features={domAnimation}>
      <m.div initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        id='home-introduction' style={{ position: 'relative', width: '100%', height: '100vh' }}>
        <Menu />

        <m.div
          initial={{ opacity: 0 }}
          animate={flashingAnimation}
          style={{
            position: 'absolute',
            top: '90%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '2',
            color: 'white',
            fontSize: '1.5em',
            textAlign: 'center'
          }}
        >
          <div style={{ fontSize: 18 }}>More About Cultural Diversity</div>
          <a href="#visualisation" style={{ color: 'white' }}>
            <DoubleRightOutlined rotate={90} style={{
              fontSize: 50
            }} /></a>
        </m.div>
      </m.div>
      <m.div
        id='visualisation'
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        style={{
          width: '100%',
          background: 'linear-gradient(135deg, #40f, #05f)',
          height: 900
        }}>
        <div className='visualisation-container' style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {!vsLoaded &&
            <m.div
              style={{
                height: '500px',
                width: '50vw', // Use viewport width for scaling
                background: 'white',
                borderRadius: '10px',
                scale,
                translateY: 90,
                marginTop: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >

              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 50,
                    }}
                    spin
                  />
                }
              />
            </m.div>
          }
          <m.div
            style={{
              height: '500px',
              width: '50vw',
              background: 'white',
              borderRadius: '10px',
              scale,
              translateY: 90,
              marginTop: 100,
              display: vsLoaded ? 'block' : 'none'
            }}
          >

            <iframe src='https://yche0208.shinyapps.io/app_cultures/' height='100%' width='100%' style={{ borderRadius: 10, border: 'none' }} onLoad={() => setVsLoaded(true)} />
          </m.div>
        </div>
      </m.div>
    </LazyMotion>
  );
}
